import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";

import Layout from "../components/Layout";
import Helmet from "react-helmet";

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        allAirport(sort: { fields: icao_code }) {
          nodes {
            name
            path
            langcode
            id
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet title={`Finavia wi-fi login pages`} />
        <ul>
          {data.allAirport.nodes.map(airport => (
            <li key={airport.id}>
              <Link to={`/${airport.path}`}>
                <strong>
                  {airport.name} - {airport.langcode}
                </strong>
              </Link>
            </li>
          ))}
        </ul>
      </Layout>
    )}
  />
);

export default IndexPage;
